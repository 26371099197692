



























import { Prop, Component, Vue, Emit } from "vue-property-decorator";
import AvatarCropper from "vue-avatar-cropper";
import { Dictionary } from "@/types/helpers";

@Component({
  components: {
    AvatarCropper,
  },
})
export default class AvatarUpload extends Vue {
  @Prop({ default: "" }) uploadUrl!: string;
  @Prop({ default: undefined }) token!: string | undefined;
  @Prop({ default: 164 }) size!: number;
  @Prop({ default: undefined }) picture: string | undefined;
  @Prop({ default: "" }) name!: string;
  response = "";

  get labels(): Dictionary<string> {
    return {
      submit: this.$t("global.submit").toString(),
      cancel: this.$t("global.cancel").toString(),
    };
  }

  get xsrfToken(): string {
    const tokenName = "XSRF-TOKEN=";
    const val = document.cookie
      .split(";")
      .filter((item) => item.trim().startsWith(tokenName))
      .map((item) => decodeURIComponent(item.replace(tokenName, "")));
    return val[0] ?? "";
  }

  handleUploaded(data: string): void {
    this.response = data;
    this.handleResponse();
  }

  @Emit("handleResponse")
  handleResponse(): string {
    return this.response;
  }
}
